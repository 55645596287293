<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <template v-if="option === 'task'">
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0">
                    우선 순위
                    <v-select
                      v-model="configData.selected"
                      class="priority"
                      :items="priorityOptions"
                      item-color="grey"
                      placeholder="1"
                      height="8vh"
                      outlined
                      dark
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0">
                    수행 횟수 <br>
                    <input
                      v-model="configData.inputNumber"
                      type="number"
                      min="0"
                      max="99"
                      class="input-task-number"
                      placeholder="00"
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-else-if="option === 'plan'">
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0">
                    경유 옵션
                    <v-select
                      v-model="configData.selected"
                      class="priority"
                      :items="stayOptions"
                      item-color="grey"
                      placeholder="시간 체류"
                      height="8vh"
                      outlined
                      dark
                      @change="configData.inputNumber = null; gpio = [0, 0, 0, 0, 0, 0, 0, 0]"
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <template v-if="configData.selected === '시간 체류'">
                    <v-col class="ma-0 pa-0">
                      체류 시간 <br>
                      <input
                        v-model="configData.inputNumber"
                        type="number"
                        min="0"
                        max="99"
                        class="input-task-number"
                        placeholder="00"
                      >
                    </v-col>
                  </template>
                  <template v-else-if="configData.selected === 'GPIO IN'">
                    <v-col class="ma-0 pa-0">
                      기다릴 신호 <br>
                      <button v-for="(item, i) in gpio"
                              :key="i"
                              :class="['button_gpio', {'active_gpio': !!item}]"
                              @click="selectGpio(i)"
                      >
                        {{ i+1 }}
                      </button>
                    </v-col>
                  </template>
                  <template v-else-if="configData.selected === 'GPIO OUT'">
                    <v-col class="ma-0 pa-0">
                      표출할 신호 <br>
                      <button v-for="(item, i) in gpio"
                              :key="i"
                              :class="['button_gpio', {'active_gpio': !!item}]"
                              @click="selectGpio(i)"
                      >
                        {{ i+1 }}
                      </button>
                    </v-col>
                  </template>
                </v-row>
              </template>
            </slot>
          </div>

          <v-row class="modal-footer ma-0 pa-0">
            <v-col
              class="btn-cancel pa-0"
              cols="6"
              type="button"
              @click="$emit('close')"
            >
              취소
            </v-col>
            <v-col
              class="btn-action pa-0"
              cols="6"
              type="button"
              @click="$emit('action')"
            >
              완료
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EventBus from '@/main.js';

  export default {
    name: 'TaskOptionModal',
    props: {
      option: {
        type: String,
        default: null
      },
      defaultNumber: {
        type: Number,
        default: null
      },
      mode: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        priorityOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        stayOptions: ['시간 체류'],
        configData: {
          selected: null,
          inputNumber: null,
        },
        gpio: [0, 0, 0, 0, 0, 0, 0, 0],
      }
    },
    created() {
      if(this.$store.state.use_gpio){
        this.stayOptions.push('GPIO IN');
        this.stayOptions.push('GPIO OUT');
      }
      if(this.defaultNumber) {
        this.configData.inputNumber = this.defaultNumber;
      }
      if (this.option === 'task') {
        this.configData.selected = 5;
      } else if (this.option === 'plan') {
        switch (this.mode){
          case 'MOVE':
            this.configData.selected = "시간 체류";
            break;
          case 'IN':
            this.gpio = this.decToBinary(this.defaultNumber);
            this.configData.selected = "GPIO IN";
            break;
          case 'OUT':
            this.gpio = this.decToBinary(this.defaultNumber);
            this.configData.selected = "GPIO OUT";
            break;
        }
      }
      EventBus.$emit('taskConfig', this.configData);
    },
    updated() {
      EventBus.$emit('taskConfig', this.configData);
    },
    methods: {
      selectGpio(index) {
        let gpio = [...this.gpio];
        gpio[index]= gpio[index]===0? 1: 0;
        this.gpio = gpio;
        let binaryStr = gpio.join('');
        this.configData.inputNumber = parseInt(binaryStr, 2);
      },
      decToBinary(num) {
        let binaryStr = num.toString(2);
        let binaryArr = binaryStr.split('');

        while (binaryArr.length < 8) {
          binaryArr.unshift('0');
        }

        return binaryArr.map((i)=>parseInt(i));
      },
    },
  }
</script>

<style lang="scss" scoped>

.button_gpio{
  margin: 3px;
  width: 70px;
  height: 70px;
  background-color: #777777;
  border-color: #773939;
  border-style:solid;
  border-width:5px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  word-break: keep-all;
}

.modal-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  height: 70%;
  margin: 0 auto;
  background-color: #393939;
  border-radius: 10px;
  transition: all .3s ease;
}

.modal-body {
  width: 100%;
  height: 60vh;
  padding: 7vh 0 0;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
  text-align: center;
  color: #ffffff;
  overflow: auto;
}

.modal-text {
  margin: 50% 0 0;
}

.btn-cancel {
  text-align: center;
  background-color: #c4c4c4;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

.btn-action {
  text-align: center;
  background-color: #7c7c7c;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

.priority {
  width: 60%;
  margin: 0 auto !important;
  background-color: black;
  border: 2px solid #545861;
}

.input-task-number {
  width: 60%;
  height: 8vh;
  margin: 1vh 0 0;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-family: Noto Sans KR,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3vw;
  color: white;
}

@media (max-width: 1024px) {
  .button_gpio{
    margin: 3px;
    width: 40px;
    height: 40px;
    background-color: #777777;
    border-color: #773939;
    border-style:solid;
    border-width:5px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
  }

}
</style>

<style>

.background .modal-mask > .modal-wrapper > .modal-container > .modal-body > .row > .col > .priority > .v-input__control > .v-input__slot {
  margin: 0;
  text-align: center;
  font-size: 3vw;
}

.background .modal-mask > .modal-wrapper > .modal-container > .modal-body > .row > .col > .priority > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > #input-37 {
  max-height: none !important;
}

.background .modal-mask > .modal-wrapper > .modal-container > .modal-body > .row > .col > .priority > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > .v-select__selection--comma {
  overflow: inherit;
}

.background .modal-mask > .modal-wrapper > .modal-container > .modal-body > .row > .col > .priority > .v-input__control > .v-text-field__details {
  display: none;
}
</style>
