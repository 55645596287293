<template>
  <v-container
    class="background"
    fill-height
    fluid
  >
    <default-modal
      v-if="showModal"
      :action="modalState.action"
      :cancel-message="modalState.cancelMessage"
      :message="modalState.message"
      @action="doAction"
      @close="closeModal"
    />
    <v-row class="left-right-container">
      <v-col class="left-area ma-1">
        <map-component
          :key="mapComponentKey"
          mode="map"
        />
      </v-col>
      <v-col class="right-area ma-1" v-if="showList">
        <template v-if="showLocations">
          <v-col
            class="btn-area"
          >
            <template v-if="workAllList.length">
              <progress-component/>
              <template v-if="$store.state.use_main_plan">
                <v-col cols="12">
                  <h1 class="title-task">
                    플랜
                  </h1>
                </v-col>
                <ul class="btn-list">
                  <li
                    v-for="(item, i) in workAllList"
                    :key="i"
                    class="btn mt-3"
                    @click="addOnceWorkToJob(i)"
                  >
                    <v-btn>
                      {{ item.work_name }}
                    </v-btn>
                  </li>
                </ul>
              </template>
              <task-component v-if="$store.state.use_main_task"/>
            </template>

            <v-col align-self="center" v-if="$store.state.use_main_location">
              <v-col cols="12 mt-5" style="padding-bottom: 0;">
                <h1 class="title-task">
                  위치 이동
                </h1>
              </v-col>
              <template v-if="locations.length">
                <ul class="btn-list">
                  <li
                    v-for="(item, i) in locations"
                    :key="i"
                    class="btn mt-3"
                    @click="moveToLocation(item.location_id, item.location_name)"
                  >
                    <v-btn>
                      {{ item.location_name }}
                    </v-btn>
                  </li>
                </ul>
              </template>
              <template v-else>
                <p class="empty">
                  저장된 위치가 없습니다.
                </p>
              </template>
            </v-col>
          </v-col>
        </template>
        <template v-else>
          <v-row
            class="move-view"
            justify="center"
          >
            <v-col
              align-self="end"
              cols="auto"
            >
              <h1 class="move-title">
                {{ destination }}
              </h1>
            </v-col>
            <template v-if="showMoving">
              <v-col
                class="move-state"
                cols="12"
              >
                <p> 이동중입니다 </p>
                <v-btn
                  class="btn-state mt-4"
                  color="#00BFB4"
                  dark
                  @click="stopMoving"
                >
                  이동정지
                </v-btn>
              </v-col>
            </template>
            <template v-if="showStop">
              <v-col
                class="move-state"
                cols="12"
              >
                <p> 이동정지 </p>
                <v-btn
                  class="btn-state mt-2"
                  color="#7c7c7c"
                  dark
                  @click="cancelMoving"
                >
                  이동취소
                </v-btn>
                <br>
                <v-btn
                  class="btn-state mt-1"
                  color="#00BFB4"
                  dark
                  @click="keepMoving"
                >
                  이동계속
                </v-btn>
              </v-col>
            </template>
            <template v-if="showArrived">
              <v-col
                class="move-state"
                cols="12"
              >
                <p> 도착했습니다 </p>
                <v-btn
                  class="btn-state mt-9"
                  color="#00BFB4"
                  dark
                  @click="cancelMoving"
                >
                  메인메뉴
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </template>
      </v-col>
      <default-header
        :config="true"
        :home="false"
        :controller="true"
        :goto="true"
        :reload="true"
        :servo="true"
        :set-pose="true"
      />
    </v-row>
  </v-container>
</template>

<script>
import EventBus from '@/main.js';
import MapComponent from '@/components/map/map.vue';
import TaskComponent from "@/components/controller/task.vue";
import ProgressComponent from "@/components/controller/progress.vue";
import DefaultModal from "@/components/modals/default_modal.vue";
import DefaultHeader from '@/components/header/default_header.vue';
import depart from '@/assets/audio/depart2.mpeg';
import arrival from '@/assets/audio/arrival2.mpeg';

export default {
  name: 'Index',
  components: {
    DefaultHeader,
    MapComponent,
    TaskComponent,
    DefaultModal,
    ProgressComponent,
  },
  data() {
    return {
      showLocations: true,
      destination: null,
      showList: true,

      showMoving: false,
      showStop: false,
      showArrived: false,

      locations: [],
      modalState: {
        message: null,
        action: null,
        cancelMessage: null,
      },
      showModal: false,

      workAllList: [],
      workId: null,
      work: null,

      mapComponentKey: 0,
    };
  },
  created() {
    this.initialize();
  },
  beforeDestroy() {
    EventBus.$off('robotStatus');
    EventBus.$off('toggleList');
    EventBus.$off('popLoadingEstimate');
    EventBus.$off('all_stop/index');
  },
  methods: {
    initialize() {
      EventBus.$off('robotStatus');
      EventBus.$off('toggleList');
      EventBus.$off('popLoadingEstimate');
      EventBus.$off('all_stop/index');

      EventBus.$on('robotStatus', this.updateRobotStatus);
      EventBus.$on('toggleList', this.toggleList);
      EventBus.$on('popLoadingEstimate', this.popLoadingEstimate);
      EventBus.$on('all_stop/index', ()=> {
        if (!this.$store.state.isTaskStop) {
          this.$store.commit('stopTask');
        }
        if (this.showMoving) {
          this.showMoving = false;
          this.showStop = true;
          this.showArrived = false;
        }
      });

      return this.fetchData()
        .then(this.fetchWorkAllList)
        .catch(err => {
          throw Error(err);
        });
    },
    fetchData() {
      return this.$axios.get('/location')
        .then(res => {
          if (res.status === 200) {
            this.locations = res.data;
          }
        });
    },
    updateRobotStatus() {
      if(this.showMoving){
        let arrivalAudio = new Audio(arrival);
        arrivalAudio.play();
      }
      this.showMoving = false;
      this.showStop = false;
      this.showArrived = true;
    },
    toggleList(){
      this.showList= !this.showList;
      this.forceRerender();
    },
    popLoadingEstimate(){
      this.modalState.message = '로봇의 위치를 조정중입니다. 잠시만 기다려 주세요.';
      this.modalState.action = 'no-action';
      this.modalState.cancelMessage = null;
      this.showModal = true;
      setTimeout(()=>{
        this.closeModal();
      }, 2000);
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.modalState.cancelMessage = null;
      this.showModal = false;
    },
    moveToLocation(id, destination) {
      this.showController = false;
      return this.$axios.put(`/nav/to/${id}`)
        .then(res => {
          if (res.status === 200) {
            let departAudio = new Audio(depart);
            departAudio.play();

            this.destination = destination;
            this.showLocations = false;
            this.showMoving = true;
            this.showStop = false;
            this.showArrived = false;
          }
        })
        .catch(err => {
          this.$toast('태스크 수행 중 명령을 할 수 없습니다', {
            position: 'top-center'
          });
          throw Error(err);
        });
    },
    doAction() {
      this.closeModal();
    },
    stopMoving() {
      return this.$axios.put('/nav/cancel')
        .then(res => {
          if (res.status === 200) {
            this.showMoving = false;
            this.showStop = true;
            this.showArrived = false;
          }
        })
        .catch(err => {
          throw Error(err);
        });
    },
    cancelMoving() {
      this.showLocations = true;
      this.showMoving = false;
      this.showStop = false;
      this.showArrived = false;
      this.destination = null;
      this.$axios.put('/nav/reset');
    },
    keepMoving() {
      return this.$axios.put('/nav/resume')
        .then(res => {
          if (res.status === 200) {
            this.showMoving = true;
            this.showStop = false;
            this.showArrived = false;
          }
        })
        .catch(err => {
          throw Error(err);
        });
    },
    addOnceWorkToJob(index) {
      this.work = this.workAllList[index];
      return this.$axios.post('/job', {
        map_id: this.work.map_id,
        work_id: this.work.work_id,
        job_state: "STBY",
        job_repeat: parseInt(1),
        job_start_count: 0,
        job_done_count: 0,
        job_fail_count: 0,
        job_order: 5
      }).then(() => {
        EventBus.$emit('socketEmit', 'changeJob');
      }).catch(err => {
        throw Error(err);
      });
    },
    fetchWorkAllList() {
      return this.$axios.get('/work')
        .then(res => {
          if (res.status === 200) {
            this.workAllList = res.data;
          }
        });
    },
    forceRerender() {
      this.mapComponentKey += 1;
    },
  }
}
</script>

<style scoped>

.title-task {
  color: #ffffff;
  font-size: 50px;
}

.empty {
  color: #ffffff;
  font-size: 20px;
  text-align: center !important;
}

.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.background > .left-right-container {
  height: 95vh;
}

.background > .left-right-container > .right-area > .btn-area {
  height: 85vh;
  overflow: auto;
}

.background > .left-right-container > .left-area {
  padding: 20px;
}

.btn-list {
  list-style-type: none;
  padding-left: 0;
}

.btn {
  width: 100%;
  height: 60px;
  text-align: center;
  text-transform: unset !important;
}

.btn > .v-btn {
  width: 100%;
  height: 100% !important;
  background-color: #00BFB4 !important;
  font-size: 40px !important;
  color: #ffffff;
  border-radius: 10px;
  font-family: sans-serif;
  font-weight: bold;
}

.btn-state {
  width: 50%;
  height: 70px !important;
  font-size: 30px !important;
  border-radius: 10px;
}

.background > .left-right-container > .right-area > .move-view {
  height: 80vh;
}

.background > .left-right-container > .right-area > .move-view .move-title {
  font-size: 5vw;
  color: #00BFB4;
}

.background > .left-right-container > .right-area > .move-view .move-state {
  text-align: center;
  font-size: 4vw;
  color: #ffffff;
}

@media (max-width: 1700px) {
  .header img {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 1024px) {

  .btn {
    width: 100%;
    height: 30px;
    font-size: 20px !important;
    border-radius: 10px;
    text-transform: unset !important;
  }

  .btn > .col {
    padding: 12px;
  }

  .btn-state {
    width: 80%;
    height: 50px !important;
    font-size: 20px !important;
    border-radius: 10px;
  }

  .btn > .v-btn {
    width: 100%;
    height: 100% !important;
    background-color: #00BFB4 !important;
    font-size: 16px !important;
    color: #ffffff;
    border-radius: 10px;
    font-family: sans-serif;
    font-weight: bold;
  }

  .title-task {
    font-size: 16px;
  }

  .empty {
    color: #ffffff;
    font-size: 12px;
    text-align: center !important;
  }
}

</style>
